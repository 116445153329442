$header-title-back-icon: '~invision/Images/Document-Page/back-button.png';

.form-header {
  float: left;
  margin-bottom: 15px;
  position: relative;

  span {
    color: $black;
    display: inline-block;
    float: left;
    font-size: .750em;
    font-weight: 400;
    line-height: 1em;
    position: relative;
  }

  .title-pre-icon {
    margin-right: 13px;

    @each $class, $file in $table-type-icons {
      &.#{$class} {
        @include retina-image($file);
      }
    }
  }

  .form-title {
    @include truncate-string;

    font-size: 1.125em;
    max-width: 80%;
  }
}
