// Vars from _json_variables.sass.json
$spacer: 15px;
$barracuda-blue: #0088ce;
$barracuda-gray-dark: #6e6e6e;
$barracuda-gray-light: #d1d2d4;
$barracuda-orange: #ff9e1b;
$barracuda-orange-burnt: #d57800;
$bcc-text-primary: #212121;
$bcc-text-secondary: #757575;
$bcc-text-tertiary: #9e9e9e;
$bcc-text-hyperlink: #1976d2;
$bcc-text-failure: #d32f2f;
$bcc-bg-primary: #ffffff;
$bcc-bg-secondary: #f7f7f7;
$bcc-bg-special: #fffdce;
$bcc-bg-lightbox: #000061;
$bcc-line-textfield: #c8c8c8;
$bcc-line-box-divider: #e2e2e2;
$bcc-line-failure: #d32f2f;
$bcc-btn-primary: #1976d2;
$bcc-btn-secondary: #fbfbfb;
$bcc-btn-tertiary: #777777;
$bcc-btn-special: #03a9fa;
$bcc-btn-cancel: transparent;
$bcc-forms-active-color: #1976d2;
$bcc-forms-default-color: #000000;
$custom-highlight-color: #0088ce;
$color-lightest: #fff;
$color-success: #52bb68;
$color-warning: #f66;
$color-active: #52bb68;
$color-disabled: #ccc;
$primary-light: #dcf1e1;
$primary-mid: #64c679;
$primary-dark: #52bb68;
$secondary-light: #b8d4f0;
$secondary-mid: #71aae1;
$secondary-dark: #4da5fd;
$tertiary-light: #f6f6f6;
$tertiary-light-2: #f2f2f2;
$tertiary-light-3: #e5e5e5;
$tertiary-light-4: #ccc;
$tertiary-mid: #999;
$tertiary-mid-2: #666;
$tertiary-dark-1: #404040;
$tertiary-dark-2: #333;
$misc-1-light: #fff6f6;
$misc-1-mid: #f9c8c8;
$misc-1-dark: #f66;
$misc-2-light: #fffbe3;
$misc-2-mid: #ffe785;
$misc-2-dark: #fc3;
$emerald: #64c679;
$persian-green: #00a085;
$pacific-blue: #00a9b8;
$havelock-blue: #65a4e1;
$east-bay: #3a5174;
$scampi: #685caa;
$bittersweet: #f66;
$cabaret: #de4674;
$supernova: #ffcb00;
$ecstasy: #f78210;
$emerald-moe: #75c986;
$emerald-larry: #97d6a4;
$emerald-curly: #cbead1;
$persian-green-moe: #33b39d;
$persian-green-larry: #66c6b6;
$persian-green-curly: #b2e2da;
$pacific-blue-moe: #33bac6;
$pacific-blue-larry: #66cbd4;
$pacific-blue-curly: #b2e5ea;
$havelock-blue-moe: #84b6e7;
$havelock-blue-larry: #a3c8ed;
$havelock-blue-curly: #d0e4f6;
$east-bay-moe: #617490;
$east-bay-larry: #8997ac;
$east-bay-curly: #c4cad5;
$scampi-moe: #867dbb;
$scampi-larry: #a49dcc;
$scampi-curly: #d1cee5;
$bittersweet-moe: #ff8482;
$bittersweet-larry: #ffa3a1;
$bittersweet-curly: #ffd0d0;
$cabaret-moe: #e56b90;
$cabaret-larry: #eb90ac;
$cabaret-curly: #f5c7d5;
$supernova-moe: #ffd533;
$supernova-larry: #ffe066;
$supernova-curly: #ffefb2;
$ecstasy-moe: #f99b40;
$ecstasy-larry: #fab470;
$ecstasy-curly: #fdd9b7;
$feedback-element-duration: .5s;
$feedback-element-delay: 9.5s;
$scrollable-table-height: 500px;
$table-min-width: 800px;
$table-max-width: 100%;
$table-width: 100%;
$modal-table-width: 640px;
$col-width-account-status: 120px;
$col-width-attachments: 100px;
$col-width-button: 80px;
$col-width-button-large: 150px;
$col-width-channel: 200px;
$col-width-checkbox: 40px;
$col-width-date: 163px;
$col-width-date-range: 302px;
$col-width-description: 250px;
$col-width-email: 225px;
$col-width-expires: 163px;
$col-width-format: 100px;
$col-width-from: 200px;
$col-width-holds: 100px;
$col-width-modified: 163px;
$col-width-message: 100%;
$col-width-name: 100%;
$col-width-options: 100%;
$col-width-owner: 200px;
$col-width-requested-by: 200px;
$col-width-results: 100px;
$col-width-review: 100px;
$col-width-roles: 175px;
$col-width-status: 165px;
$col-width-subject: 100%;
$col-width-toggle: 100px;
$col-width-title: 100%;
$col-width-username: 175px;

@import 'node_modules/view-components/components/constants';

:global {
  .user-management {
    .user-image {
      left: $layout-offset-small - 5;
    }
  }

  .exports,
  .audits,
  .reports {
    // scss-lint:disable SelectorDepth, NestingDepth

    [data-table-id='audits'] .header-cell[data-cell-key='status'] {
      width: 5%;
    }

    [data-job-status='pending'] span {
      color: $color-dark-yellow-2;
    }

    [data-job-status='running'] span {
      color: $color-primary-dark;
    }

    [data-job-status='error'] span {
      color: $color-misc-alert-dark;
    }

    [data-job-status='complete'],
    [data-job-status='done'] {
      span {
        color: $color-secondary-dark;
        cursor: pointer;
      }
    }

    .job-status img {
      margin-right: 7px;
      vertical-align: -5px;
    }
  }

  .randomized-flag {
    margin-bottom: 15px;
  }

  .table-header-mask {
    background-color: $color-tertiary-light-1;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  .search-name-label {
    @include truncate-string;

    display: inline-block;
    max-width: 60%;
  }

  .search-count-label {
    display: inline-block;
    overflow: hidden;

    [data-item='loading-indicator'] {
      display: inline-block;
      margin-top: 0;
    }

    .spinner-container {
      top: 6px;
      width: 24px;
    }
  }

  .search-help {
    font-style: italic;
    position: absolute;
    right: 0;
    top: -$layout-offset-small - 5;
    white-space: nowrap;
  }

  .table-content-group {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .main-body-inner .infinite-scroll-inner {
    padding-right: $layout-offset-medium - 10;
  }

  .split-content-group {
    height: 100%;
    position: relative;

    .infinite-scroll-inner {
      padding: 0;
    }

    .table-control-group {
      right: 15px;
      top: -46px;
    }

    // scss-lint:disable SelectorDepth
    .action-wrapper > div {
      left: 0;
      opacity: 1;
    }
    // scss-lint:enable SelectorDepth

    td {
      border: 0;
    }
  }

  .body-cell .center-text {
    text-align: center;
  }

  .body-cell .table-link-text {
    color: $color-secondary-dark !important;
    cursor: pointer !important;

    &.run-search {
      margin-right: $layout-offset-small - 10;
    }
  }

  .loading-cell {
    float: left;
    margin-bottom: 7px;
    margin-left: $layout-offset-medium - 5;
    position: relative;
  }

  .tables-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .table-header,
  .table-content {
    margin-right: 20px;
    padding-right: 20px;

    table {
      // scss-lint:disable ImportantRule
      border: 1px solid $color-tertiary-light-3 !important;
    }
  }

  .table-header {
    overflow: hidden;
  }

  .attachment-image {
    vertical-align: -6px;
  }

  .review-image {
    vertical-align: -4px;
  }

  .table-content {
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 40px;
  }

  [data-row-id='topPusher'] td,
  [data-row-id='bottomPusher'] td,
  .placeholder td {
    // scss-lint:disable ImportantRule

    background-color: transparent !important;
    border-color: transparent !important;
  }

  [data-cell-key='username'],
  [data-cell-key='email'],
  [data-cell-key='name'] {
    @include fixed-cell-width(250px, 115px);
  }

  [data-cell-key='requester'],
  [data-cell-key='created-user'] {
    @include fixed-cell-width(250px, 150px);
  }

  [data-cell-key='roles'] {
    @include fixed-cell-width(150px, 90px);
  }

  [data-cell-key='last-modified-time'] {
    @include fixed-cell-width(200px, 140px);
  }

  [data-cell-key='requestedDate'] {
    @include fixed-cell-width(200px, 160px);
  }

  [data-cell-key='active'] {
    @include fixed-cell-width(90px, 90px);
  }

  [data-cell-key='results'],
  [data-cell-key='itemCount'],
  [data-cell-key='last-run-hits'] {
    @include fixed-cell-width(150px, 100px);
    text-align: right;
  }

  [data-cell-key='results'] {
    @include fixed-cell-width(150px, 100px);
    text-align: right;
  }

  [data-cell-key='format'] {
    @include fixed-cell-width(150px, 100px);
  }

  [data-cell-key='dateRange'] {
    @include fixed-cell-width(350px, 250px);
  }

  [data-cell-key='openAsset'],
  [data-cell-key='run-search'] {
    cursor: pointer;
  }

  [data-cell-key='hold'] {
    @include fixed-cell-width(90px, 90px);
  }

  [data-cell-key='review'] {
    @include fixed-cell-width(75px, 75px);

    img {
      margin-left: 12px;
    }
  }

  [data-cell-key='holds'],
  [data-cell-key='comment-count'],
  [data-cell-key='attachment-count'] {
    @include fixed-cell-width(75px, 75px);
  }

  [data-cell-key='from.verbatim'] {
    @include fixed-cell-width(220px, 220px);
  }

  [data-cell-key='date'] {
    @include fixed-cell-width(170px, 170px);
  }

  .table-control-group {
    position: absolute;
    right: $layout-offset-medium + $layout-offset-small + 10;
    top: -($layout-offset-medium + 10);
  }

  .table-control-group-standalone {
    margin-top: -($layout-offset-medium + 10);
    position: absolute;
    right: 0;
  }

  .header-control-group {
    position: relative;
    right: $layout-offset-medium + $layout-offset-small + 10;
    top: -($layout-offset-medium + 10);
  }

  .vertical-scroll-bar {
    // scss-lint:disable ImportantRule

    background-color: $color-tertiary-light-1;
    padding-left: 9px;
    padding-right: $layout-offset-small - 5;
    padding-top: $layout-table-cell-height + 3;
    width: 40px !important;
  }

  .body-cell mark {
    background-color: $color-highlight;
  }

  .table-control-group-inner {
    float: right;
    height: $layout-offset-medium;
    position: relative;
    width: auto;
  }

  .search-field {
    display: inline-block;
    height: $layout-offset-medium;
    position: relative;
    width: 300px;
  }
}
