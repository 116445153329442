.new-hold-reason-view .app-form {
  .switcher-status-text {
    margin-left: 7px;
    margin-top: 1px;
    text-transform: uppercase;
    vertical-align: -1px;
  }

  .blue-text {
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .status-on {
    @extend %green-text;
  }

  .status-off {
    @extend %red-text;
  }
}
