.search-criteria,
.search-form-content {
  @extend %vertical-with-right-padding;
}

.edit-search-form {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 44px;
  top: 0;

  span {
    line-height: 1.2em;
  }

  .status-text {
    float: right;
    margin-right: 15px;
  }

  .search-name {
    @include truncate-string;
    font-size: 1.125em;
    max-width: 60%;
  }

  .detail-box {
    float: left;
    margin: {
      bottom: 0;
      right: 42px;
    }
    position: relative;
    width: auto;

    .title {
      color: $color-tertiary-dark-2;
      font-weight: $font-weight-heavy-bold;
      margin-bottom: 10px;
    }

    .value {
      color: $black;
      font-weight: 400;
    }

    span {
      display: block;
      float: none;
      font-weight: 300;
      line-height: 1em;
      width: auto;
    }
  }
}
