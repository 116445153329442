// too much !important to be maually cleaned out :(
// scss-lint:disable ImportantRule

$full-width-input-size: 555px;
$half-width-input-size: 263px;

.app-form {
  float: left;
  position: relative;

  div,
  section,
  span,
  input,
  textarea {
    float: left;
    position: relative;
  }

  input,
  textarea {
    &:focus {
      border-color: $color-primary-mid;
    }
  }

  section {
    width: 100%;
  }

  span:not([data-type='skip-styles']) {
    color: $gray1;
    display: inline-block;
    font-size: $font-size-2;
    font-weight: 400;
    line-height: 1em;
  }

  input {
    height: 30px;
  }

  textarea {
    height: 100px;
    padding: 10px $input-fields-left-padding;
  }

  .header-title {
    color: $black;
    font-size: $font-size-1;
    font-weight: 400;
  }

  .form-title {
    color: $color-tertiary-dark-2 !important;
    display: block;
    font-weight: $font-weight-heavy-bold !important;
    margin-bottom: $layout-offset-2;
    width: 100%;

    &.required::after {
      color: $error;
      content: '\0020*';
    }
  }

  .roles-selection {
    .form-title {
      display: inline-block;
      width: auto;
    }

    .help-icon {
      margin-left: 5px;
      margin-top: -4px;
      vertical-align: top;
    }
  }

  .form-group {
    margin-bottom: $layout-offset-3;
  }

  .full-width {
    width: $full-width-input-size;
  }

  .half-width {
    margin-right: $layout-offset-1;
    width: $half-width-input-size;
  }

  .new-line {
    clear: left;
  }

  .app-form-header {
    margin-bottom: 26px;
  }

  .app-form-control-buttons {
    margin-top: $layout-offset-2;
    padding-bottom: 80px;

    button {
      margin-right: $layout-offset-2;
    }

    // scss-lint:disable QualifyingElement
    button[data-action='delete'] {
      float: right;
      margin-right: 0;
    }
  }

  .custom-content {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .with-multiple-selection span {
    display: inline-block !important;
    width: auto !important;

    &.custom-checkbox {
      margin-top: 5px;
      width: 18px !important;
    }
  }

  .btn-dropdown span {
    color: $black;
  }

  .btn-danger {
    float: right;
  }

  .form-error {
    bottom: -($layout-offset-errors + 5);
    display: none;
    position: absolute;
    width: 100%;

    span {
      color: $error;
      line-height: $layout-offset-errors;
      white-space: nowrap;
      width: 100%;
    }
  }

  .show-error {
    display: block;
  }

  .has-error {
    input,
    textarea,
    .btn-dropdown {
      border: 1px solid $error !important;
    }
  }
}
