%vertical-with-right-padding {
  .vertical-section {
    position: relative;

    &.table-heading,
    &.table-listing,
    &.heading,
    &.listing {
      margin-right: 55px;
    }
  }
}
