// Vars from _json_variables.sass.json
$spacer: 15px;
$barracuda-blue: #0088ce;
$barracuda-gray-dark: #6e6e6e;
$barracuda-gray-light: #d1d2d4;
$barracuda-orange: #ff9e1b;
$barracuda-orange-burnt: #d57800;
$bcc-text-primary: #212121;
$bcc-text-secondary: #757575;
$bcc-text-tertiary: #9e9e9e;
$bcc-text-hyperlink: #1976d2;
$bcc-text-failure: #d32f2f;
$bcc-bg-primary: #ffffff;
$bcc-bg-secondary: #f7f7f7;
$bcc-bg-special: #fffdce;
$bcc-bg-lightbox: #000061;
$bcc-line-textfield: #c8c8c8;
$bcc-line-box-divider: #e2e2e2;
$bcc-line-failure: #d32f2f;
$bcc-btn-primary: #1976d2;
$bcc-btn-secondary: #fbfbfb;
$bcc-btn-tertiary: #777777;
$bcc-btn-special: #03a9fa;
$bcc-btn-cancel: transparent;
$bcc-forms-active-color: #1976d2;
$bcc-forms-default-color: #000000;
$custom-highlight-color: #0088ce;
$color-lightest: #fff;
$color-success: #52bb68;
$color-warning: #f66;
$color-active: #52bb68;
$color-disabled: #ccc;
$primary-light: #dcf1e1;
$primary-mid: #64c679;
$primary-dark: #52bb68;
$secondary-light: #b8d4f0;
$secondary-mid: #71aae1;
$secondary-dark: #4da5fd;
$tertiary-light: #f6f6f6;
$tertiary-light-2: #f2f2f2;
$tertiary-light-3: #e5e5e5;
$tertiary-light-4: #ccc;
$tertiary-mid: #999;
$tertiary-mid-2: #666;
$tertiary-dark-1: #404040;
$tertiary-dark-2: #333;
$misc-1-light: #fff6f6;
$misc-1-mid: #f9c8c8;
$misc-1-dark: #f66;
$misc-2-light: #fffbe3;
$misc-2-mid: #ffe785;
$misc-2-dark: #fc3;
$emerald: #64c679;
$persian-green: #00a085;
$pacific-blue: #00a9b8;
$havelock-blue: #65a4e1;
$east-bay: #3a5174;
$scampi: #685caa;
$bittersweet: #f66;
$cabaret: #de4674;
$supernova: #ffcb00;
$ecstasy: #f78210;
$emerald-moe: #75c986;
$emerald-larry: #97d6a4;
$emerald-curly: #cbead1;
$persian-green-moe: #33b39d;
$persian-green-larry: #66c6b6;
$persian-green-curly: #b2e2da;
$pacific-blue-moe: #33bac6;
$pacific-blue-larry: #66cbd4;
$pacific-blue-curly: #b2e5ea;
$havelock-blue-moe: #84b6e7;
$havelock-blue-larry: #a3c8ed;
$havelock-blue-curly: #d0e4f6;
$east-bay-moe: #617490;
$east-bay-larry: #8997ac;
$east-bay-curly: #c4cad5;
$scampi-moe: #867dbb;
$scampi-larry: #a49dcc;
$scampi-curly: #d1cee5;
$bittersweet-moe: #ff8482;
$bittersweet-larry: #ffa3a1;
$bittersweet-curly: #ffd0d0;
$cabaret-moe: #e56b90;
$cabaret-larry: #eb90ac;
$cabaret-curly: #f5c7d5;
$supernova-moe: #ffd533;
$supernova-larry: #ffe066;
$supernova-curly: #ffefb2;
$ecstasy-moe: #f99b40;
$ecstasy-larry: #fab470;
$ecstasy-curly: #fdd9b7;
$feedback-element-duration: .5s;
$feedback-element-delay: 9.5s;
$scrollable-table-height: 500px;
$table-min-width: 800px;
$table-max-width: 100%;
$table-width: 100%;
$modal-table-width: 640px;
$col-width-account-status: 120px;
$col-width-attachments: 100px;
$col-width-button: 80px;
$col-width-button-large: 150px;
$col-width-channel: 200px;
$col-width-checkbox: 40px;
$col-width-date: 163px;
$col-width-date-range: 302px;
$col-width-description: 250px;
$col-width-email: 225px;
$col-width-expires: 163px;
$col-width-format: 100px;
$col-width-from: 200px;
$col-width-holds: 100px;
$col-width-modified: 163px;
$col-width-message: 100%;
$col-width-name: 100%;
$col-width-options: 100%;
$col-width-owner: 200px;
$col-width-requested-by: 200px;
$col-width-results: 100px;
$col-width-review: 100px;
$col-width-roles: 175px;
$col-width-status: 165px;
$col-width-subject: 100%;
$col-width-toggle: 100px;
$col-width-title: 100%;
$col-width-username: 175px;

@use 'sass:math';
@import 'node_modules/view-components/components/constants';

$criteria-dropdown-width: 345px;
$criteria-dropdown-max-height: 425px;
$alert-color: #ff9;

%active-criteria {
  [data-type="move"][data-opened="false"],
  [data-type="remove"] {
    display: block;
  }

  [data-type="content"] {
    @include app-border($color-primary-dark);
  }

  .dirty-criteria {
    left: $layout-border-size + $layout-offset-medium;
    width: 6px - $layout-border-size;
  }
}

%criteria-wrapper {
  cursor: default;
  margin-bottom: $layout-offset-small;
  padding: 0 $layout-offset-medium;
  position: relative;
  width: 100%;
}

%first-criteria-wrapper {
  @extend %criteria-wrapper;
  z-index: 1;

  &:hover {
    @extend %active-criteria;
  }
}

%first-criteria-wrapper-opened {
  @extend %criteria-wrapper;
  @extend %active-criteria;
  z-index: 999;
}

.first-criteria-wrapper {
  @extend %first-criteria-wrapper;
}

.first-criteria-wrapper-opened {
  @extend %first-criteria-wrapper-opened;
}

.sibling-criteria-wrapper {
  @extend %first-criteria-wrapper;
  margin-top: $layout-offset-medium + $font-size-medium;
}

.sibling-criteria-wrapper-opened {
  @extend %first-criteria-wrapper-opened;
  margin-top: $layout-offset-medium + $font-size-medium;
}

.dirty-criteria {
  background-color: $alert-color;
  bottom: $layout-border-size;
  left: $layout-offset-medium;
  position: absolute;
  top: $layout-border-size;
  width: 6px;
}

%icons {
  display: none;
  position: absolute;
  top: math.div(($layout-offset-medium - $layout-icon-dimensions), 2);
  z-index: 1;
}

.exact {
  margin-bottom: 16px;
  margin-top: 12px;
}

.delete-icon {
  @extend %icons;

  cursor: pointer;
  left: 0;
  top: 2px;
}

.criteria-view {
  @include no-select;
  @include app-border($color-white);
  @include box-shadow($layout-box-shadow-light);

  background-color: $color-white;
  cursor: pointer;
  height: $layout-offset-medium;
  padding: 0 $layout-offset-small;

  span {
    line-height: $layout-offset-medium - (2 * $layout-border-size);
  }
}

.criteria-type-label {
  color: $color-tertiary-dark-2 !important;
  cursor: pointer !important;
  font-weight: $layout-font-weight-bold;
}

.green-header-text {
  color: $color-primary-dark;
  text-decoration: underline;
}

.additional-header-text {
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;

  span {
    color: $color-tertiary-dark-2 !important;
  }
}

.arrow-icon {
  float: right;
  position: relative;
  top: math.div(($layout-offset-medium - $layout-icon-dimensions - 2), 2);
}

%parent-relation {
  color: $color-primary-dark;
  left: $layout-offset-small + $layout-offset-medium;
  position: absolute;
  top: -($layout-offset-medium + math.div($font-size-medium, 2));
}

.parent-relation-label {
  @extend %parent-relation;

  cursor: pointer;
}

.parent-relation-label-disabled {
  @extend %parent-relation;

  cursor: default;
}

%criteria-dropdown {
  position: absolute;
  right: (2 * $layout-offset-medium) + $layout-icon-dimensions;
  top: $layout-offset-medium - $layout-border-size;
  width: $criteria-dropdown-width;
}

.criteria-dropdown {
  @extend %criteria-dropdown;
  @include app-border($color-primary-dark);
  @include box-shadow($layout-box-shadow-medium);

  background-color: $color-white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 0;
  max-height: $criteria-dropdown-max-height;
  padding: $layout-offset-small $layout-offset-small (2 * $layout-offset-medium);
  z-index: 5;
}

%criteria-content-wrapper {
  position: relative;
  width: 100%;
}

%criteria-content-group {
  @extend %criteria-content-wrapper;
  margin-bottom: $layout-offset-small;
}

.radio-buttons-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-large;
}

.vertical-divider {
  border-left: $layout-border-size solid $color-tertiary-light-3;
  height: $layout-offset-large;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}

%criteria-buttons {
  bottom: 0;
  position: absolute;
}

.apply-button {
  @extend %criteria-buttons;

  left: 0;
  width: 60%;
}

.cancel-button {
  @extend %criteria-buttons;

  right: 0;
  width: 40%;

  button,
  [disabled] {
    border-color: $color-white;
  }
}

.fill-button {
  width: 100%;
}

%radio-selectors {
  position: absolute;
  top: math.div($layout-offset-small, 2);
}

.dependency-select {
  @extend %radio-selectors;

  left: $layout-offset-small;
}

.relation-select {
  @extend %radio-selectors;

  right: 0;
}

// Keywords criteria related styles
.keywords-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

.keywords-group-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-medium;
  max-height: $layout-offset-medium;
}

.keywords-text-editor-wrapper {
  @extend %criteria-content-group;
}

// Holds criteria related styles
.holds-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

// Attachments criteria related styles
.attachments-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

// Participants criteria related styles
.adv-participants-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

.adv-participants-group-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-medium;
  max-height: $layout-offset-medium;
}

.adv-participants-text-editor-wrapper {
  @extend %criteria-content-group;
}

// Sizes criteria related styles
.sizes-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-small;
}

.sizes-group-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-medium;
  max-height: $layout-offset-medium;
}

.sizes-group-wrapper-taller {
  @extend %criteria-content-group;
  height: (2 * $layout-offset-medium) + $layout-offset-small;
}

%value-controllers {
  position: absolute;
  top: 0;
}

.size-numerical-value {
  @extend %value-controllers;

  left: 0;
  width: 68%;
}

.size-value {
  @extend %value-controllers;

  right: 0;
  width: 27%;
}

// Dates criteria related styles
.dates-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-small;
}

.dates-group-wrapper {
  @extend %criteria-content-group;

  $group-height: ($layout-offset-medium * 2) + $layout-offset-small;
  height: $group-height;
  max-height: $group-height;
}

%dropdown-inline {
  position: absolute;
  top: $layout-offset-medium + $layout-offset-small;
}

.dropdown-inline-from {
  @extend %dropdown-inline;
  left: 0;
}

.dropdown-inline-to {
  @extend %dropdown-inline;
  right: 0;
}

// Keyword list criteria related styles
.keyword-lists-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

.keyword-lists-group-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-medium;
  max-height: $layout-offset-medium;
}

// User list criteria related styles
.user-lists-criteria-dropdown-content {
  @extend %criteria-content-wrapper;
  margin-bottom: -$layout-offset-medium;
}

.user-lists-group-wrapper {
  @extend %criteria-content-group;
  height: $layout-offset-medium;
  max-height: $layout-offset-medium;
}
