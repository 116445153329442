$font-family-sans-serif: 'Open Sans', Helvetica, Arial, sans-serif;
$body-bg: $brand-background;
$brand-primary: #52a7fa;
$brand-danger: #f66;
$brand-nav: #f6f6f6;
$state-danger-text: $brand-danger;
$state-danger-bg: $brand-danger;

$input-border-radius: 0;
$input-border-color: #e5e5e5;

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$search-icon: '~invision/Images/Document-Table/search-icon.png';
$delete-icon: '~invision/Images/Document-Table/delete-icon.png';
