// Vars from _json_variables.sass.json
$spacer: 15px;
$barracuda-blue: #0088ce;
$barracuda-gray-dark: #6e6e6e;
$barracuda-gray-light: #d1d2d4;
$barracuda-orange: #ff9e1b;
$barracuda-orange-burnt: #d57800;
$bcc-text-primary: #212121;
$bcc-text-secondary: #757575;
$bcc-text-tertiary: #9e9e9e;
$bcc-text-hyperlink: #1976d2;
$bcc-text-failure: #d32f2f;
$bcc-bg-primary: #ffffff;
$bcc-bg-secondary: #f7f7f7;
$bcc-bg-special: #fffdce;
$bcc-bg-lightbox: #000061;
$bcc-line-textfield: #c8c8c8;
$bcc-line-box-divider: #e2e2e2;
$bcc-line-failure: #d32f2f;
$bcc-btn-primary: #1976d2;
$bcc-btn-secondary: #fbfbfb;
$bcc-btn-tertiary: #777777;
$bcc-btn-special: #03a9fa;
$bcc-btn-cancel: transparent;
$bcc-forms-active-color: #1976d2;
$bcc-forms-default-color: #000000;
$custom-highlight-color: #0088ce;
$color-lightest: #fff;
$color-success: #52bb68;
$color-warning: #f66;
$color-active: #52bb68;
$color-disabled: #ccc;
$primary-light: #dcf1e1;
$primary-mid: #64c679;
$primary-dark: #52bb68;
$secondary-light: #b8d4f0;
$secondary-mid: #71aae1;
$secondary-dark: #4da5fd;
$tertiary-light: #f6f6f6;
$tertiary-light-2: #f2f2f2;
$tertiary-light-3: #e5e5e5;
$tertiary-light-4: #ccc;
$tertiary-mid: #999;
$tertiary-mid-2: #666;
$tertiary-dark-1: #404040;
$tertiary-dark-2: #333;
$misc-1-light: #fff6f6;
$misc-1-mid: #f9c8c8;
$misc-1-dark: #f66;
$misc-2-light: #fffbe3;
$misc-2-mid: #ffe785;
$misc-2-dark: #fc3;
$emerald: #64c679;
$persian-green: #00a085;
$pacific-blue: #00a9b8;
$havelock-blue: #65a4e1;
$east-bay: #3a5174;
$scampi: #685caa;
$bittersweet: #f66;
$cabaret: #de4674;
$supernova: #ffcb00;
$ecstasy: #f78210;
$emerald-moe: #75c986;
$emerald-larry: #97d6a4;
$emerald-curly: #cbead1;
$persian-green-moe: #33b39d;
$persian-green-larry: #66c6b6;
$persian-green-curly: #b2e2da;
$pacific-blue-moe: #33bac6;
$pacific-blue-larry: #66cbd4;
$pacific-blue-curly: #b2e5ea;
$havelock-blue-moe: #84b6e7;
$havelock-blue-larry: #a3c8ed;
$havelock-blue-curly: #d0e4f6;
$east-bay-moe: #617490;
$east-bay-larry: #8997ac;
$east-bay-curly: #c4cad5;
$scampi-moe: #867dbb;
$scampi-larry: #a49dcc;
$scampi-curly: #d1cee5;
$bittersweet-moe: #ff8482;
$bittersweet-larry: #ffa3a1;
$bittersweet-curly: #ffd0d0;
$cabaret-moe: #e56b90;
$cabaret-larry: #eb90ac;
$cabaret-curly: #f5c7d5;
$supernova-moe: #ffd533;
$supernova-larry: #ffe066;
$supernova-curly: #ffefb2;
$ecstasy-moe: #f99b40;
$ecstasy-larry: #fab470;
$ecstasy-curly: #fdd9b7;
$feedback-element-duration: .5s;
$feedback-element-delay: 9.5s;
$scrollable-table-height: 500px;
$table-min-width: 800px;
$table-max-width: 100%;
$table-width: 100%;
$modal-table-width: 640px;
$col-width-account-status: 120px;
$col-width-attachments: 100px;
$col-width-button: 80px;
$col-width-button-large: 150px;
$col-width-channel: 200px;
$col-width-checkbox: 40px;
$col-width-date: 163px;
$col-width-date-range: 302px;
$col-width-description: 250px;
$col-width-email: 225px;
$col-width-expires: 163px;
$col-width-format: 100px;
$col-width-from: 200px;
$col-width-holds: 100px;
$col-width-modified: 163px;
$col-width-message: 100%;
$col-width-name: 100%;
$col-width-options: 100%;
$col-width-owner: 200px;
$col-width-requested-by: 200px;
$col-width-results: 100px;
$col-width-review: 100px;
$col-width-roles: 175px;
$col-width-status: 165px;
$col-width-subject: 100%;
$col-width-toggle: 100px;
$col-width-title: 100%;
$col-width-username: 175px;

@import 'node_modules/view-components/components/constants';
@import 'TableViewActions/ActionsBase';

$metadata-navigation-container-width: 300px;
$metadata-navigation-right: 50px;
$metadata-navigation-container-padding: 65px;

:global {
  .rendered-asset,
  .my-archive-split-view,
  .add-remove-holds,
  .action-wrapper {
    header {
      position: relative;
    }

    .metadata-navigation-container {
      bottom: 0;
      position: absolute;
      right: $metadata-navigation-right;
      top: 0;
      width: $metadata-navigation-container-width;
    }

    .accordion-view {
      height: 100%;
      margin-right: 5px;
      overflow-y: auto;
      padding-left: $layout-offset-small;
      padding-right: $layout-offset-small;
    }

    .hold-reason-row {
      float: left;
      position: relative;
      width: 100%;
    }

    .content-checkbox {
      left: 0;
      position: absolute;
      top: 5px;
    }

    .asset-download {
      bottom: 10px;
      margin-bottom: -5px;
      position: absolute;
      right: 65px;
    }

    .action-wrapper {
      float: right;
      left: auto;
      margin-left: 0;
      opacity: 1;
    }

    .holds-wrapper {
      bottom: $layout-offset-medium * 2;
      display: block;
      left: $layout-offset-small;
      margin-left: 0;
      overflow-y: auto;
      position: absolute;
      right: $layout-offset-small - 10;
      top: $layout-offset-small;
    }

    .download-anchor {
      bottom: 0;
      left: 0;
      outline: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    [data-field='app-breadcrumb'] {
      padding-bottom: 5px;
    }

    .headline-label {
      width: 50%;
    }

    .metadata-item {
      display: inline;
      float: left;
      font-weight: $layout-font-weight-normal;
      line-height: $layout-offset-medium - 10;
      margin-bottom: 5px;
      position: relative;
      width: 100%;
      word-break: break-all;

      // scss-lint:disable NestingDepth, SelectorDepth
      span {
        display: inline;
      }
      // scss-lint:enable NestingDepth, SelectorDepth
    }

    .metadata-label {
      color: $color-tertiary-mid-1;
    }

    .metadata-content {
      color: $color-tertiary-dark-2;
    }

    .holds-label {
      @include truncate-string;

      color: $color-tertiary-dark-2;
      cursor: pointer;
      padding-left: $layout-checkbox-dimensions + 10;
      padding-right: $layout-offset-small;
    }

    .first-meta-item {
      margin-top: $layout-offset-small + 5;
    }

    .lock-icon {
      margin-left: 3px;
      vertical-align: -5px;
    }

    .control-buttons {
      border-top: 1px solid $color-tertiary-light-3;
      bottom: 0;
      left: $layout-offset-small;
      padding-top: $layout-offset-small;
      position: absolute;
      right: $layout-offset-medium - 5;
      z-index: 1;
    }

    .save-button {
      margin-left: $layout-offset-medium + 5;
      margin-right: $layout-offset-small;
    }

    iframe {
      border: 0;
    }

    .vertical-section {
      position: relative;
    }

    .iframe-row {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
    }

    .iframe-container {
      background-color: $color-white;
      bottom: 0;
      left: 0;
      position: absolute;
      right: $metadata-navigation-container-width +
        $metadata-navigation-container-padding;
      top: 0;
    }
  }

  .my-archive-split-view {
    .iframe-container {
      right: 0;
    }

    .table-control-group {
      // scss-lint:disable NestingDepth, SelectorDepth
      .action-wrapper {
        margin-left: $layout-offset-medium;
        // scss-lint:enable NestingDepth, SelectorDepth
      }
    }
  }
}
