@use 'sass:math';
$bg-color-1: rgba(0, 0, 0, .5);
$shadow-color-1: rgba(255, 255, 255, .5);
$shadow-color-2: rgba(0, 0, 0, .15);

@mixin retina-image($image, $width: null) {
  display: inline-block;
  background-image: image-url($image);
  @if $width {
    height: math.div((math.div(image-height($image), 2) * $width), (math.div(image-width($image), 2)));
    width: $width;
  } @else {
    height: math.div(image-height($image), 2);
    width: math.div(image-width($image), 2);
  }

  background-repeat: no-repeat;
  background-size: 100%;
}

@mixin position-image-vertically-within(
  $image,
  $container-height,
  $adjustment: 0
) {
  $h1: math.div($container-height, 2);
  $h2: math.div(image-height($image), 4);

  $position: $h1 - $h2 + $adjustment;

  position: absolute;
  top: $position;
}

@mixin lion-scrollbar {
  overflow-y: auto;

  // scss-lint:disable VendorPrefix
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-color-1;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 1px $shadow-color-1;
  }
}

@mixin truncate-string {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin fixed-cell-width($max-width, $min-width: false) {
  max-width: $max-width;
  white-space: nowrap;
  width: 1%;
  @if $min-width {
    min-width: $min-width;
  }

  > span {
    @include truncate-string;
  }
}

@mixin dropdown-list-menu($width: $dropdown-menu-width) {
  .dropdown-list-menu {
    position: absolute;
    z-index: 5;

    ol {
      @include box-shadow(0 3px 5px 0 $shadow-color-2);

      background-color: $dropdown-background-color;
      display: inline-block;
      left: 0;
      padding: 0;
      position: relative;
      top: -$dropdown-menu-border-size;
      width: $width;
      z-index: 2;
    }

    li {
      border: $dropdown-menu-border-size solid $brand-blue;
      border-top: 0;
      color: $brand-blue;
      cursor: pointer;
      display: block;
      font-size: 14px;
      height: auto;
      line-height: $dropdown-menu-height;
      padding-left: 15px;
      text-transform: uppercase;

      &:hover {
        background-color: $brand-blue;
        color: $white;
      }

      &:first-of-type {
        border-top: $dropdown-menu-border-size solid $brand-blue;
      }
    }
  }
}

%fill-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

%pane-list-value {
  @include truncate-string();
  max-width: 80%;
}

@mixin noselect {
  // scss-lint:disable VendorPrefix
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
