// scss-lint:disable ImportantRule
$loading-image: '~invision/Images/Loader/loader-icon-1.png';

// scss-lint:disable ColorKeyword
$checkbox-checkmarks: (
  blue: '~invision/Images/Document-Page/checkmark-blue.png',
  white: '~invision/Images/Document-Page/checkmark-white.png'
);
// scss-lint:enable ColorKeyword

$status-text-icons: (
  completed: $checkmark-green-icon,
  failed: $failure-white-small-icon,
  started: $loading-icon2
);

$checkbox-dimension: 18px;

$default-system-scrollbar-width: 25px;

@mixin switcher-colorize($color) {
  .switcher-bg {
    background-color: $color !important;
  }

  .switcher-text {
    color: $color !important;
  }
}

.contents {
  position: relative;
}

.loading {
  color: $gray-dark2;
  font-size: .875em;
  font-weight: 300;
  padding-top: 5px;
  text-transform: uppercase !important;
}

.image-loading {
  @include retina-image($loading-image);

  margin-left: 5px;
  vertical-align: -5px;
}

%blue-text {
  color: $blue1 !important;
}

.blue-text {
  @extend %blue-text;
}

%green-text {
  color: $green1 !important;
}

.green-text {
  @extend %green-text;
}

%red-text {
  color: $red1 !important;
}

.red-text {
  @extend %red-text;
}

.btn .image-loading {
  position: absolute;
  top: 4px;
}

.main-body-static {
  float: left;
  margin-bottom: $layout-offset-medium;
  margin-top: $layout-offset-medium;
  padding-left: $layout-sidebar-width + $layout-offset-medium;
  position: relative;
  width: 100%;

  .main-body-inner {
    margin-right: $layout-offset-medium;
    position: relative;
  }

  header {
    margin-right: 0;
  }
}

.left-hand-nav {
  height: 100%;
}

.left-hand-nav-static {
  bottom: 0;
  left: 0;
  position: fixed;
  top: 60px;
  z-index: 50;
}

.main-body {
  bottom: 0;
  left: $layout-sidebar-width;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  .main-body-inner {
    bottom: 30px;
    left: 30px;
    position: absolute;
    right: 0;
    top: 30px;
  }

  .loading {
    color: $gray-dark2;
    font-size: .875em;
    font-weight: 300;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .image-loading {
    @include retina-image($loading-image);

    margin-left: 5px;
    vertical-align: -5px;
  }
}

.main-body-my-archive-split-view {
  .loading {
    margin-left: 30px;
  }

  .main-body-inner {
    left: 0;
  }

  header {
    margin-left: 10px;
  }
}

.vertical-height-manager {
  @extend %fill-container;

  [data-maximized] {
    @include lion-scrollbar;
  }
}

.switcher-status-text {
  font-size: $font-size-2;
  line-height: 30px !important;
  margin-left: 7px;
  margin-top: 1px;
  text-transform: uppercase;
  vertical-align: -1px;

  &.small-size {
    line-height: 1em !important;
  }
}

.status-on {
  @extend %green-text;
}

.status-off {
  @extend %red-text;
}

.disabled-state {
  opacity: .65;
}

.custom-switcher {
  @include noselect;

  border: 0 !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;

  &.normal-size {

    .switcher-bg {
      border-radius: 5px;

      height: 30px;
      width: 50px;
    }

    .switcher-button {
      border-radius: 5px;

      height: 26px !important;
      margin-top: 2px;
      width: 26px !important;
    }

    &.opened-state .switcher-button {
      margin-left: 22px;
    }

    // scss-lint:disable NestingDepth
    &.grayed-state,
    &.closed-state {
      .switcher-button {
        margin-left: 2px;
      }
    }

    &.disabled-state .switcher-bg {
      cursor: default;
    }
  }

  &.small-size {

    .switcher-bg {
      border-radius: 2px;

      height: 12px;
      vertical-align: -3px;
      width: 20px;
    }

    .switcher-button {
      border-radius: 2px;

      height: 10px !important;
      margin-bottom: 2px;
      margin-top: 1px;
      width: 10px !important;
    }

    &.opened-state .switcher-button {
      margin-left: 9px;
    }

    // scss-lint:disable NestingDepth
    &.grayed-state,
    &.closed-state {
      .switcher-button {
        margin-left: 1px;
      }
    }

    .switcher-text {
      font-size: 12px;
      line-height: 1em;
      margin-left: 5px;
    }
  }

  .switcher-button {
    background-color: $white !important;
    position: static;
  }

  &.opened-state {
    @include switcher-colorize($green1);
  }

  &.closed-state {
    @include switcher-colorize($red1);
  }

  &.grayed-state {
    @include switcher-colorize($gray-light1);
  }
}

.custom-checkbox {
  border: 1px solid $gray-light1;
  border-radius: 2.5px;
  cursor: pointer;
  height: $checkbox-dimension;
  padding: 0 !important;
  width: $checkbox-dimension;

  &:not(.checked):hover {
    // scss-lint:disable ColorVariable,ColorKeyword
    @include retina-image(map-get($checkbox-checkmarks, blue));

    border-color: $brand-primary;
    height: $checkbox-dimension;
    width: $checkbox-dimension;
  }

  &.checked {
    // scss-lint:disable ColorVariable,ColorKeyword
    @include retina-image(map-get($checkbox-checkmarks, white));

    background-color: $brand-primary;
    border-color: $brand-primary;
    height: $checkbox-dimension;
    width: $checkbox-dimension;
  }
}

.status-text {
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  text-transform: uppercase !important;

  @each $class, $file in $status-text-icons {
    &.#{$class}:before {
      @include retina-image($file);

      content: '';
      margin-right: 5px;
      max-height: 30px;
      vertical-align: -5px;
    }
  }

  &.completed {
    color: $green1;
  }

  &.failed {
    color: $red1;
  }

  &.started {
    color: $gray-dark2;
  }
}

.editable-table-cell {
  &.edit-content {
    position: relative;

    .editable-cell-input {
      border: 0;
      font-size: 12px;
      height: 24px;
      padding: 0 50px 0 10px;
    }

    .blue-text {
      cursor: pointer;
      font-size: 12px;
      margin-top: 4px;
      position: absolute;
      right: 45px;
    }
  }
}
