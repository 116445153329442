// this file is all overrides!
// scss-lint:disable ImportantRule

.help-block {
  font-size: .95em;
  margin-top: .2em;
}

input,
textarea {
  @include box-shadow(none);
  @include transition(none);
  // scss-lint:disable DeclarationOrder, QualifyingElement
  input[placeholder] {
    color: $gray1;
    font-style: italic;
    font-weight: 300;
  }

  border: 1px solid $gray-light1;
  font-size: 12px;
  font-weight: 400;
  padding-left: $input-fields-left-padding;
  resize: none;
  width: 100%;

  &:focus {
    @include box-shadow(none);

    border: 1px solid $blue1;
    outline: none;
  }

  &[disabled] {
    background-color: $gray-light2;
  }
}

.btn.btn-dropdown[data-field="roles"] {
  &[disabled] {
    // .btn-dropdown should be refactored app-wide if possible
    // to get rid of !important rules
    background-color: $gray-light2 !important;
    border: 1px solid $color-tertiary-light-4;
    opacity: 1;

    &::after {
      background-image: none;
    }
  }
}


.blue-text-button {
  background: none;
  color: $brand-primary !important;
  cursor: pointer;
  font-size: .75em !important;
  padding: 5px 10px;
  text-align: center;
  text-transform: inherit !important;
}

.search-wrapper {
  position: relative;

  &.add-input input {
    padding-right: 40px;
  }

  &.with-search-icon input {
    padding-left: 28px;
  }

  input {
    border: 1px solid $input-border-color;
    color: $black;
    font-size: .875em;
    height: 30px;
    padding: {
      left: 15px;
      right: 30px;
      top: 4px;
    }
    width: 100%;

    &:focus {
      @include box-shadow(none);

      border-color: $brand-primary;
      outline: none;
    }
  }

  span {
    display: inline-block;
  }

  .search-icon {
    left: 5px;
    position: absolute;
    top: 5px;
    z-index: 1;

    &.image {
      @include retina-image($search-icon);
    }
  }

  .delete-icon {
    @include retina-image($delete-icon);

    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;

    &.off {
      display: none;
    }
  }

  .add-search {
    color: $gray-light1;
    cursor: default;
    line-height: 30px !important;
    position: absolute;
    right: 10px;
    text-transform: uppercase !important;
    top: 0;
  }

  .active-button {
    color: $blue1;
    cursor: pointer;
  }
}
