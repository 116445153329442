$gray: #999;
$dark-gray: #666;
$black: #000;
$light-blue: #7dbdfb;

$inactive-button: #ccc;
$error: #f66;
$placeholder-color: #999;

// colors for refactored system
$white: #fff;
$black: #000;
$gray-light1: #ccc;
$gray-light2: #f6f6f6;
$gray-light3: #e5e5e5;
$gray1: #999;
$gray2: #a6a6a6;
$gray-dark1: #333;
$gray-dark2: #666;
$green1: #52bb68;
$red1: #f66;
$blue1: #52a7fa;
$orange1: #f59d00;

$accordion-gray-border: $gray-light3;
$accordion-label-color: $gray1;
$accordion-control-button-bg: $gray-light1;
$success-color: #57c57f;
$error-color: $red1;
$input-border-color: $gray-light3;

$shadow-background-color: #dfdfdf;

$legal-text-color: $gray-light1;
$legal-border-color: $gray-light3;

$live-chat-text: $white;

$list-bottom-separator-border: $gray-light3;

$menu-item-bottom-border: #eee;

// colors about new Design Guide
// https://docs.google.com/document/d/1fkUH5hQxbKiB-xYpmW0ks7RkHusElyr5ozZQiPrLBZI/edit
// We should change all the color reference in style files to fit the following system
//  continuously when developing, then delete the old declared color variables
$color-white: #fff;
$color-black: #000;

$color-primary-light: #dcf1e1;
$color-primary-mid: #64c679;
$color-primary-dark: #52bb68;

$color-secondary-light: #b8d4f0;
$color-secondary-mid: #71aee1;
$color-secondary-dark: #4da5fd;

$color-tertiary-light-1: #f6f6f6;
$color-tertiary-light-2: #f2f2f2;
$color-tertiary-light-3: #e5e5e5;
$color-tertiary-light-4: #ccc;
$color-tertiary-mid-1: #999;
$color-tertiary-mid-2: #666;
$color-tertiary-dark-1: #404040;
$color-tertiary-dark-2: #333;

