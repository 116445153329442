// scss-lint:disable ImportantRule
// Override bootstrap so we can tuck ourselves up against the side of the
// browser
$white: #fff;
$shadow-color-1: rgba(0, 0, 0, .25);
$shadow-color-2: rgba(0, 0, 0, .15);

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.main-body {
  background-color: $white;
}

.badge {
  font-weight: 400;
  padding: 3px 5.5px;
  text-align: center;
}

.badge-danger {
  background-color: $brand-danger;
}

.nav > li {
  > a:hover,
  > a:focus {
    background-color: inherit;
  }
}

.dropdown-menu-inner {
  box-shadow: 0, 0, 40px, 0, $shadow-color-1;

  list-style-type: none;
  margin: 0;
  padding: 0;

  li:hover .text {
    color: $blue1 !important;
  }
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid transparent;
}

.panel-group {
  background-color: $brand-nav;
}

.panel-body {
  background-color: $white;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: -1;
}

.panel {
  background-color: $brand-nav;
  border: 1px solid $brand-nav;
  border-bottom: 1px solid $accordion-gray-border;
  border-radius: 0;

  .panel-heading {
    height: 43px;
    padding: 0;

    h4 {
      float: left;
      height: 43px;
      position: relative;
      width: 100%;
    }

    a {
      line-height: 43px;
      padding: 0 15px;
    }

    span:first-of-type {
      margin-right: 3px;
    }
  }

  &:last-of-type {
    border-bottom: 1px solid $brand-nav;
  }

  + .panel {
    margin-top: 0;
  }

  .panel-title {
    // scss-lint:disable SelectorDepth

    > a,
    > small,
    > .small,
    > small > a,
    > .small > a {
      color: $gray-dark2;
      display: block;
      font-size: .75em;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}

.dropdown-wrapper {
  display: inline-block !important;
  position: relative !important;

  &.wide-dropdown {
    display: block !important;
    float: none !important;

    .dropdown-menu span:not(.custom-checkbox),
    .btn-dropdown {
      padding: 0 15px !important;
    }
  }

  button {
    width: 100% !important;

    .selected-dropdown-text {
      float: left;
      height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }
  }

  .dropdown-menu {
    border-color: $gray-light1;
    border-radius: 0;
    border-top: 0;
    border-width: 1px;
    box-shadow: 0 3px 5px 0 $shadow-color-2;
    cursor: pointer;
    margin: 0;
    min-width: inherit;
    overflow-y: auto;
    padding: 0;
    width: 100%;

    // scss-lint:disable NestingDepth, SelectorDepth
    li {
      border-bottom: 1px solid $gray-light1;
      float: left;
      padding: 0 $input-fields-left-padding;
      width: 100%;

      &:last-of-type {
        border-bottom: 0;
      }

      &:not(.with-multiple-selection):hover {
        background-color: $blue1;

        span,
        data {
          color: $white !important;
        }
      }

      &:hover .custom-checkbox:not(.checked) {
        @include retina-image($checkmark-blue-icon);
      }
    }

    span {
      @include truncate-string();
      display: inline;
      font-size: $font-size-2 !important;
      font-weight: 300 !important;
      line-height: 30px !important;
      padding: 0 7.5px !important;
    }

    .custom-checkbox {
      margin-left: 7.5px !important;
      padding: 0 !important;
      vertical-align: -5px !important;
    }
  }
}

.btn {
  border-radius: 0;

  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  letter-spacing: .05em !important;
  outline: none;
  padding: 3.5px 10px 5.5px;
  text-align: center !important;
  width: 105px;

  &.inactive:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &.inactive,
  &[disabled] {
    cursor: default !important;
  }

  &.btn-primary {
    background-color: $brand-primary !important;
    border: 1px solid $brand-primary !important;
    color: $white !important;

    &.inactive,
    &[disabled] {
      background-color: $inactive-button !important;
      border: 1px solid $inactive-button !important;
      color: $white !important;
    }
  }

  &.btn-default {
    background-color: transparent !important;
    border: 1px solid $brand-primary !important;
    color: $brand-primary !important;

    &.inactive,
    &[disabled] {
      border: 1px solid $inactive-button !important;
      color: $inactive-button !important;
    }
  }

  &.btn-danger {
    background-color: $white !important;
    border: 1px solid $error !important;
    color: $error !important;

    &[disabled] {
      border: 1px solid $inactive-button !important;
      color: $inactive-button !important;
    }
  }

  &.btn-danger-filled {
    background-color: $error !important;
    border: 1px solid $error !important;
    color: $white !important;

    &[disabled] {
      background-color: $gray1 !important;
      border: 1px solid $gray1 !important;
    }
  }

  &.btn-dropdown {
    background-color: $white !important;
    border: 1px solid $gray-light1 !important;
    display: inline-block !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    padding: 0 27px 0 $input-fields-left-padding !important;
    text-align: left !important;

    span {
      @include truncate-string();
      font-size: $font-size-2 !important;
      font-weight: 300 !important;
      line-height: 30px !important;
      text-transform: none !important;
      width: 100% !important;
    }

    .placeholder-text {
      color: $gray1 !important;
      font-style: italic !important;
    }

    &[aria-expanded="true"]::after {
      @include retina-image($up-arrow-icon);

      content: '';
      margin-top: 5px;
      position: absolute;
      right: 5px;
    }

    &::after {
      @include retina-image($down-arrow-icon);

      content: '';
      margin-top: 5px;
      position: absolute;
      right: 5px;
    }
  }
}

// hide blue outline on elements that are forced to
// be treated as buttons by the browser.
[role=button],
[role=checkbox] {
  outline: none;
}
