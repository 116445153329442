@import 'node_modules/view-components/components/constants';

$content-width: 540px;
$content-height: 375px;
$color-light-grey: #e5e5e5;
$color-blue: #4da5fd;

$color-grey: #999;

:global {
  .settings {
    .action-wrapper {
      float: right;
    }
  }

  .float-right {
    float: right;
  }

  .download-anchor {
    bottom: 0;
    left: 0;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .action-wrapper[data-action='send_email-action'] {
    > div {
      height: $content-height;
    }

    .content-main {
      height: auto;
    }
  }

  %action-wrapper {
    display: inline-block;
    margin-left: $layout-offset-medium;
    position: relative;

    > div {
      height: $content-height;
      left: auto;
      margin-right: -100px;
      opacity: 1;
      right: 0;
      text-align: start;
      top: 0;
      width: $content-width;
    }

    %content-checkbox {
      position: absolute;
      top: 5px;
    }

    .content-checkbox {
      @extend %content-checkbox;
    }

    .select-all-checkbox {
      @extend %content-checkbox;

      left: $layout-offset-small - 5;
      z-index: 2;
    }

    %action-label {
      color: $color-tertiary-dark-2;
      cursor: pointer;
      font-weight: $font-weight-bold;
      height: inherit;
      line-height: inherit;
    }

    .action-label {
      @extend %action-label;
    }

    .action-image {
      margin-right: 4px;
      vertical-align: -5px;
    }

    .labeless-action-image {
      margin: 10px 10px 10px -5px;
    }

    .active-action-label,
    .opened-action-label {
      @extend %action-label;
    }
  }

  .download {
    margin-top: 5px;
  }

  .action-wrapper-disabled {
    @extend %action-wrapper;

    opacity: .5;

    .action-label {
      cursor: not-allowed;
    }
  }

  .action-wrapper {
    @extend %action-wrapper;
    @include no-select;

    .content-header {
      border-bottom: 1px solid $color-tertiary-light-3;
      height: $layout-offset-medium + 5;
      margin-top: -5px;
      padding: 0 $layout-offset-small - 5;
      position: relative;

      // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
      > span {
        margin: 0;
      }
      // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
    }

    .search-field-wrapper {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .search-field {
      width: 100%;
    }

    .search-input-wrapper {
      background-color: $color-tertiary-light-1;
      height: $layout-offset-medium + 5;
    }
  }

  %content-main {
    font-size: 12px;
    height: $content-height - $layout-offset-small -
      ($layout-offset-medium * 4) - 2;
    overflow-y: auto;
    padding-left: $layout-offset-small - 5;
    padding-right: $layout-offset-small - 5;
    padding-top: $layout-offset-small - 10;
    position: relative;
    width: 100%;
  }

  .content-main {
    @extend %content-main;
  }

  .content-main-comments {
    @extend %content-main;
    height: 205px !important;
    margin-top: 2px !important;
  }

  .content-main-comments-tab {
    @extend %content-main;
    bottom: 326px !important;
    position: absolute !important;
    top: 0 !important;
    min-height: 50px !important;
  }

  %content-footer {
    border-top: 1px solid $color-tertiary-light-3;
    bottom: 0;
    height: $layout-offset-medium * 2 + $layout-offset-small;
    left: $layout-offset-small;
    padding: $layout-offset-small 0;
    position: absolute;
    right: $layout-offset-small;
  }

  .content-footer {
    @extend %content-footer;
  }

  .content-footer-comments {
    @extend %content-footer;
    height: 345px;
  }

  .footer-buttons {
    margin-top: 12px;
  }

  .new-comment-textarea {
    min-height: 40px !important;
  }

  .add-button {
    float: left;
    margin-right: $layout-offset-small;
  }

  .cancel-button {
    float: right;
  }

  .border-bottom {
    border-bottom: 1px solid $color-light-grey;
    padding-bottom: 20px;
  }

  .comment-mtime {
    padding-left: 4px;
    padding-right: 4px;
  }

  .edit-button {
    color: $color-blue;
    height: 20px;
    padding-right: 4px;
  }

  .edited-flag {
    line-height: 1;
  }

  .delete-button {
    border-left: .25px solid $color-light-grey;
    color: $color-blue;
    height: 20px;
    padding-left: 4px;
  }

  .comment-body {
    line-height: 2;
  }

  .disabled {
    color: $color-grey;
    cursor: auto;
  }

  %text-label {
    color: $color-tertiary-dark-2 !important;
  }

  .text-label {
    @extend %text-label;
    @include truncate-string;

    font-weight: $font-weight-bold;
    padding-left: $layout-offset-medium;
  }

  .rules-label {
    @extend %text-label;

    font-weight: $font-weight-bold;
    white-space: normal;
    line-height: 1.5 !important;
    padding-bottom: 8px;
  }

  .red-color {
    color: #FF0000 !important;
  }

  %head-label {
    @extend %text-label;

    font-weight: $font-weight-heavy-bold;
  }

  .head-label {
    @extend %head-label;
  }

  .selected-label {
    @extend %head-label;

    padding-left: $layout-offset-medium;
  }

  .export-row,
  .hold-reason-row,
  .role-row {
    height: $layout-offset-medium;
    margin: $layout-offset-small - 10 0;
    padding-right: $layout-offset-small;
    position: relative;
  }

  .form-table {
    margin-top: $layout-offset-small - 5;
    width: 100%;

    // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
    th {
      vertical-align: top;
    }
    // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
  }

  %first-column {
    width: $layout-offset-large * 2;

    // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
    span::after {
      margin-left: $layout-offset-small - 10;
    }
    // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
  }

  .first-column {
    @extend %first-column;

    // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
    > div {
      margin-top: $layout-offset-small - 5;
    }
    // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
  }

  .first-column-with-input {
    @extend %first-column;

    // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
    > div {
      margin-top: $layout-offset-small - 7;
    }
    // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
  }

  .second-column {
    // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
    div {
      margin-bottom: $layout-offset-small - 5;
    }
    // scss-lint:enable SelectorDepth, NestingDepth, ImportantRule
  }

  .flex-box {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .end {
    justify-self: flex-end;
  }
}

[data-action='comment-action'] > div {
  // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
  height: 640px !important;
  right: 75px;
}

[data-action='filter-action'] > div {
  // scss-lint:disable SelectorDepth, NestingDepth, ImportantRule
  border: 0;
  box-shadow: none;
  height: auto;
  padding: 0;
  width: 660px;
}

.dialog-lower-section {
  span {
    display: block;
    font-weight: $layout-font-weight-normal;
    text-align: center;
  }
}

.dialog-control-buttons {
  margin: $layout-offset-large auto 0;
  text-align: center;

  .continue-button {
    margin-right: $layout-offset-medium;
  }
}

.status-image {
  > img {
    display: block;
    margin: $layout-offset-large auto 0;
  }
}

.status-content {
  margin-top: $layout-offset-small - 5;
  text-align: center;

  > span {
    display: inline-block;
  }
}
