@import 'node_modules/view-components/components/constants';

%form-group {
  margin-bottom: $layout-offset-small;
  position: relative;
}

%group-label {
  margin-right: $layout-offset-medium;
  padding-left: 10px;
}
