@use 'sass:math';
@import 'node_modules/view-components/components/constants';

:global {
  .on-hover {
    // scss-lint:disable ImportantRule
    border: 3px solid $color-tertiary-light-4 !important;
  }
}

$divider-distance: 10px;

.advanced-view {
  background-color: $color-white;
  bottom: $layout-offset-large;
  left: 0;
  padding: $layout-offset-medium;
  position: absolute;
  right: 0;
  top: $layout-offset-medium + 10px;

  .button-group {
    bottom: $layout-offset-small;
    margin-bottom: 0;
    position: absolute;
  }

  .divider {
    bottom: $layout-offset-medium * 2 + 10px;
    left: $layout-offset-medium;
    position: absolute;
    right: $layout-offset-medium;
    width: auto;
  }

  .form-content {
    bottom: $layout-offset-medium * 2 + ($divider-distance * 2);
    left: $layout-offset-medium;
    overflow: auto;
    position: absolute;
    right: 0;
    top: $layout-offset-medium;
    width: auto;
  }
}

.top-divider {
  margin-top: -$divider-distance;
}

.advanced-edit-wrapper {
  bottom: 0;
  left: 0;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: $layout-offset-medium - 10px;
  position: absolute;
  right: 0;
  top: 0;
}

%group-positioner {
  position: relative;
  width: 100%;
}

.root-group-wrapper,
.root-group-wrapper-inner {
  @extend %group-positioner;
}

.group-with-children {
  margin-left: $layout-offset-large - $layout-border-size-big;
}

%nested-group-wrapper {
  @extend %group-positioner;

  margin-bottom: $layout-offset-small;
  padding: 0 $layout-offset-medium;

  &:hover {
    > [data-type='move'],
    > [data-type='remove'] {
      display: block;
    }

    > [data-type='group-inner'] {
      @include app-border($color-tertiary-light-4, $layout-border-size-big);
    }
  }
}

.first-nested-group-wrapper {
  @extend %nested-group-wrapper;
}

.sibling-nested-group-wrapper {
  @extend %nested-group-wrapper;
  margin-top: $layout-offset-medium + $font-size-medium;
}

.nested-group-wrapper-inner {
  @extend %group-positioner;
  @include app-border($color-tertiary-light-3, $layout-border-size-big);

  cursor: default;
  padding: $layout-offset-medium $layout-offset-small - ($layout-border-size-big *
        2);
}

.collapsed-group-inner {
  @include app-border($color-tertiary-light-3, $layout-border-size-big);

  height: $layout-offset-medium;
  padding-left: $layout-offset-small;
  width: 100%;
}

.collapsed-group-text {
  color: $color-tertiary-mid-1;
  margin-top: -$layout-border-size-big;

  strong {
    color: $color-tertiary-dark-2;
  }
}

%parent-relation {
  color: $color-primary-dark !important;
  left: $layout-offset-small + $layout-offset-medium;
  position: absolute;
  top: -($layout-offset-medium + math.div($font-size-medium, 2));
}

.parent-relation-label {
  @extend %parent-relation;

  cursor: pointer;
}

.parent-relation-label-disabled {
  @extend %parent-relation;

  cursor: default;
}

%icons {
  display: none;
  position: absolute;
  top: math.div(($layout-offset-medium - $layout-icon-dimensions), 2);
  z-index: 1;
}

.delete-icon {
  @extend %icons;

  cursor: pointer;
  left: 0;
  top: 2px;
}

.arrow-icon {
  cursor: pointer;
  position: absolute;
  right: $layout-offset-medium + $layout-offset-small - 2;
  top: math.div(($layout-offset-medium - $layout-icon-dimensions - 2), 2);
}

.query-counts {
  color: $color-tertiary-dark-1;
  font-weight: $font-weight-bold;
  width: 100%;
}
