// Vars from _json_variables.sass.json
$spacer: 15px;
$barracuda-blue: #0088ce;
$barracuda-gray-dark: #6e6e6e;
$barracuda-gray-light: #d1d2d4;
$barracuda-orange: #ff9e1b;
$barracuda-orange-burnt: #d57800;
$bcc-text-primary: #212121;
$bcc-text-secondary: #757575;
$bcc-text-tertiary: #9e9e9e;
$bcc-text-hyperlink: #1976d2;
$bcc-text-failure: #d32f2f;
$bcc-bg-primary: #ffffff;
$bcc-bg-secondary: #f7f7f7;
$bcc-bg-special: #fffdce;
$bcc-bg-lightbox: #000061;
$bcc-line-textfield: #c8c8c8;
$bcc-line-box-divider: #e2e2e2;
$bcc-line-failure: #d32f2f;
$bcc-btn-primary: #1976d2;
$bcc-btn-secondary: #fbfbfb;
$bcc-btn-tertiary: #777777;
$bcc-btn-special: #03a9fa;
$bcc-btn-cancel: transparent;
$bcc-forms-active-color: #1976d2;
$bcc-forms-default-color: #000000;
$custom-highlight-color: #0088ce;
$color-lightest: #fff;
$color-success: #52bb68;
$color-warning: #f66;
$color-active: #52bb68;
$color-disabled: #ccc;
$primary-light: #dcf1e1;
$primary-mid: #64c679;
$primary-dark: #52bb68;
$secondary-light: #b8d4f0;
$secondary-mid: #71aae1;
$secondary-dark: #4da5fd;
$tertiary-light: #f6f6f6;
$tertiary-light-2: #f2f2f2;
$tertiary-light-3: #e5e5e5;
$tertiary-light-4: #ccc;
$tertiary-mid: #999;
$tertiary-mid-2: #666;
$tertiary-dark-1: #404040;
$tertiary-dark-2: #333;
$misc-1-light: #fff6f6;
$misc-1-mid: #f9c8c8;
$misc-1-dark: #f66;
$misc-2-light: #fffbe3;
$misc-2-mid: #ffe785;
$misc-2-dark: #fc3;
$emerald: #64c679;
$persian-green: #00a085;
$pacific-blue: #00a9b8;
$havelock-blue: #65a4e1;
$east-bay: #3a5174;
$scampi: #685caa;
$bittersweet: #f66;
$cabaret: #de4674;
$supernova: #ffcb00;
$ecstasy: #f78210;
$emerald-moe: #75c986;
$emerald-larry: #97d6a4;
$emerald-curly: #cbead1;
$persian-green-moe: #33b39d;
$persian-green-larry: #66c6b6;
$persian-green-curly: #b2e2da;
$pacific-blue-moe: #33bac6;
$pacific-blue-larry: #66cbd4;
$pacific-blue-curly: #b2e5ea;
$havelock-blue-moe: #84b6e7;
$havelock-blue-larry: #a3c8ed;
$havelock-blue-curly: #d0e4f6;
$east-bay-moe: #617490;
$east-bay-larry: #8997ac;
$east-bay-curly: #c4cad5;
$scampi-moe: #867dbb;
$scampi-larry: #a49dcc;
$scampi-curly: #d1cee5;
$bittersweet-moe: #ff8482;
$bittersweet-larry: #ffa3a1;
$bittersweet-curly: #ffd0d0;
$cabaret-moe: #e56b90;
$cabaret-larry: #eb90ac;
$cabaret-curly: #f5c7d5;
$supernova-moe: #ffd533;
$supernova-larry: #ffe066;
$supernova-curly: #ffefb2;
$ecstasy-moe: #f99b40;
$ecstasy-larry: #fab470;
$ecstasy-curly: #fdd9b7;
$feedback-element-duration: .5s;
$feedback-element-delay: 9.5s;
$scrollable-table-height: 500px;
$table-min-width: 800px;
$table-max-width: 100%;
$table-width: 100%;
$modal-table-width: 640px;
$col-width-account-status: 120px;
$col-width-attachments: 100px;
$col-width-button: 80px;
$col-width-button-large: 150px;
$col-width-channel: 200px;
$col-width-checkbox: 40px;
$col-width-date: 163px;
$col-width-date-range: 302px;
$col-width-description: 250px;
$col-width-email: 225px;
$col-width-expires: 163px;
$col-width-format: 100px;
$col-width-from: 200px;
$col-width-holds: 100px;
$col-width-modified: 163px;
$col-width-message: 100%;
$col-width-name: 100%;
$col-width-options: 100%;
$col-width-owner: 200px;
$col-width-requested-by: 200px;
$col-width-results: 100px;
$col-width-review: 100px;
$col-width-roles: 175px;
$col-width-status: 165px;
$col-width-subject: 100%;
$col-width-toggle: 100px;
$col-width-title: 100%;
$col-width-username: 175px;

@import 'node_modules/view-components/components/constants';

$gh-height: 60px;

:global {
  // Overwrite the inherited rules from the old sass files or bootstrap rules
  //   Delete it when the old files and bootstrap are removed
  [data-form-type^="dropdown"] {
    label {
      display: inline-block;
      font-weight: normal;
      margin-bottom: 0;
      max-width: initial;
    }
  }

  [data-content],
  [data-sub-header],
  [data-sub-content] {
    position: relative;
  }

  .full-height {
    height: 100%;
  }

  .content-view {
    background-color: $color-tertiary-light-1;
    height: auto;
    margin-left: $layout-sidebar-width;
    margin-top: $gh-height;
    position: static;
  }

  .personal-archive-view {
    background-color: $color-tertiary-light-1;
    height: auto;
    margin-top: $gh-height;
    position: static;
  }

  .infinite-scroll {
    overflow: initial;
  }

  .infinite-scroll-inner {
    margin-right: -$layout-offset-small;
    overflow-x: hidden;
    padding-right: $layout-offset-small;
  }

  .content-view-inner {
    float: left;
    padding: $layout-offset-medium;
    position: relative;
    width: 100%;
  }

  .saved-searches,
  .exports,
  .hold-reasons,
  .user-management,
  .search-results,
  .hold-reason-assets,
  .results-unsaved-search,
  .results-saved-search,
  .rendered-asset,
  .my-archive,
  .my-archive-split-view,
  .save-current-search,
  .save-new-search,
  .new-user,
  .settings,
  .edit-user {
    .main-body {
      background-color: $color-tertiary-light-1;
    }

    .vertical-section {
      overflow: inherit;
    }

    // scss-lint:disable NestingDepth, SelectorDepth
    .content header {
      height: auto;
      margin-right: 30px;
      padding-bottom: $layout-offset-small;
    }
    // scss-lint:enable NestingDepth, SelectorDepth
  }

  .search-results,
  .hold-reason-assets {
    [data-field="app-breadcrumb"] {
      margin-bottom: -($layout-offset-small - 5);
    }

    .details-label {
      margin-left: $layout-offset-small - 10;
      overflow: hidden;
      text-decoration: none;
      vertical-align: -3px;
    }
  }

  .save-new-search,
  .save-current-search {
    .vertical-section {
      position: relative;
    }

    .main-body-inner {
      right: 30px;
    }
  }
}

.form-wrapper {
  background-color: $color-white;
  border: $layout-border-size solid $color-tertiary-light-3;
  bottom: 0;
  left: 0;
  padding: $layout-offset-medium;
  padding-bottom: $layout-offset-medium * 2 + $layout-offset-small;
  padding-right: $layout-offset-small;
  position: absolute;
  right: 0;
  top: 0;
}

.form-inner-wrapper {
  height: 100%;
  margin-right: -$layout-offset-small;
  overflow-y: auto;
  padding-right: $layout-offset-small;
  position: relative;
  width: 100%;
}

%big-label {
  color: $color-tertiary-dark-1;
  display: block;
  font-size: $font-size-more-medium;
  font-weight: $layout-font-weight-bold;
}

.form-big-label {
  @extend %big-label;
}

.form-big-label-inline {
  @extend %big-label;

  display: inline-block;
}

.form-green-label {
  @extend %big-label;

  color: $color-primary-dark;
  margin-bottom: $layout-offset-medium - 10;
}

.form-section-with-border {
  border-bottom: $layout-border-size solid $color-tertiary-light-3;
  margin-bottom: $layout-offset-small;
}

.form-row {
  display: block;
  margin-bottom: $layout-offset-medium - 10;
}

.medium-size-input {
  width: 450px;
}

.divider {
  border-top: 1px solid $color-tertiary-light-3;
  display: block;
  margin-bottom: $layout-offset-small;
  margin-top: $layout-offset-small - 5;
}

.inline-content {
  > * {
    display: inline-block;
  }
}

.float-right {
  float: right;
}

.action-group {
  position: absolute;
  right: $layout-offset-small - 5;
  top: -($layout-offset-medium + 5);
}

.action-group-inner {
  position: relative;
}

.green-text-label {
  color: $color-primary-dark;
  font-size: $font-size-more-medium;
  font-weight: $font-weight-bold;
}

.form-headline-label {
  display: block;
  margin-bottom: $layout-offset-small;
  margin-top: $layout-offset-small - 10;
}

%form-text {
  display: block;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
}

.form-red-text {
  @extend %form-text;

  color: $color-misc-alert-dark;
}

.form-green-text {
  @extend %form-text;

  color: $color-primary-dark;
}
