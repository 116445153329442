body {
  font: {
    size: 16px;
    weight: 300;
  }
}

small {
  font-size: 74%;
}

a {
  text-decoration: none;

  &:hover,
  &:visited,
  &:focus {
    text-decoration: none;
  }
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

