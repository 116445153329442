@import 'node_modules/view-components/components/constants';

.info-text {
  color: $color-tertiary-dark-1;
  display: inline;
  font-weight: $font-weight-bold;
  line-height: initial;

  span {
    display: inline;
  }
}

.info-bold {
  color: $color-tertiary-dark-1;
  font-weight: $font-weight-heavy-bold;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

.link {
  font-weight: $font-weight-bold;
  text-decoration: none;
}

.input-label {
  color: $color-tertiary-dark-1;
  font-weight: $font-weight-heavy-bold;
}

input {
  // scss-lint:disable ImportantRule
  display: block;
  margin-bottom: 20px;
  width: 500px !important;
}

.value {
  line-height: initial;
  margin-bottom: 20px;
  width: 100%;
}
